<script lang="js">
  import { generateChart } from 'vue-chartjs'

  const CustomLine = generateChart('custom-line', 'bar')

  export default {
    extends: CustomLine,
    // eslint-disable-next-line vue/require-prop-types
    props: ['type'],
    mounted() {
      const self = this
      this.$root.$on(self.type + '_event', (data) => {
        self.renderChart(data.chartData, data.options)
      })
    },
  }
</script>
