












































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import { appendSitePrefix } from '@/utils/routeUtils'
import { Watch } from 'vue-property-decorator'
import VerticalBarChart from '@/components/VerticalBarChart.vue'
import PageTab from '@/components/ui/PageTab.vue'
import { DateTime } from 'luxon'
import { LEGACY_BASE_URL } from '@/config'
import router from '@/router'

@Component({
  components: {
    ListView,
    VerticalBarChart,
    PageTab,
  },
})
export default class List extends Vue {
  $refs: Vue['$refs'] & {
    listView: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateOneFilter: any
    }
  }

  headers = []
  routes = {}
  topActions = []
  rowActions = []
  showCustomerDetails = false
  customerTypes = {}
  customerGroups = {}
  customerSubGroups = {}
  actionHeaders = []
  advancedHeaders = []
  sortOrder = 'asc'
  sortColumn = 'name'

  private created() {
    this.showCustomerDetails = this.getStoredParamBool('showCustomerDetails')
    this.$axios.get('/v4/site/customers/initial-data').then((response) => {
      this.customerTypes = response?.data?.customerTypes || {}
      this.customerGroups = response?.data?.customerGroups || {}
      this.customerSubGroups = response?.data?.customerSubGroups || {}
      this.prepHeaders()
    })
  }

  mounted() {
    this.prepHeaders()

    this.rowActions = [
      {
        id: 'show',
        route: (item) => {
          return appendSitePrefix('/' + item.id + '/customer/show/null')
        },
      },
    ]

    this.topActions = [
      {
        id: 'new',
        template: 'new',
        route: () => {
          return appendSitePrefix('/N0/new_customer_and_car/main/null')
        },
      },
      {
        id: 'customer groups',
        type: 'link',
        class: 'text-none',
        route: () => {
          return appendSitePrefix('/customers/U/customer_group/list/null/root')
        },
        link: true,
      },
      {
        id: 'customer sub groups',
        type: 'link',
        class: 'text-none',
        route: () => {
          return appendSitePrefix('/customers/U/customer_sub_group/list/null/root')
        },
        link: true,
      },
      {
        id: 'export to csv',
        label: 'Export to CSV',
        type: 'link',
        class: 'text-none',
        download: true,
        route: () => {
          return `${LEGACY_BASE_URL.replace(
            '{siteId}',
            router.currentRoute.params.siteId,
          )}/customers/U/customer/export/null/root/${this.sortOrder}=${this.sortColumn}`
        },
        link: true,
      },
    ]
  }

  private prepHeaders() {
    this.actionHeaders = [
      {
        text: 'Customer no',
        value: 'customerNumber',
      },
      {
        text: 'Type',
        value: 'type',
        filter: {
          items: Object.keys(this.customerTypes).map((key) => {
            return {
              text: this.customerTypes[key],
              value: key,
            }
          }, this),
        },
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Invoice email',
        value: 'invoiceEmail',
      },
      {
        text: 'Invoice emailed',
        value: 'invoiceEmailed',
        sortable: false,
        filter: {
          items: [
            { text: this.$t('Yes'), value: 1 },
            { text: this.$t('No'), value: 0 },
          ],
        },
        hidden: !this.showCustomerDetails,
      },
      {
        text: 'Mobile',
        value: 'mobile',
      },
      {
        text: 'Phone',
        value: 'phone',
      },
      {
        text: 'City',
        value: 'city',
      },
      {
        text: 'Payer',
        value: 'defaultPayerName',
        hidden: !this.showCustomerDetails,
      },
      {
        text: 'Price list',
        value: 'priceListName',
      },
      {
        text: 'Comp.reg.no / Social.sec.no',
        value: 'identificationNumber',
        hidden: !this.showCustomerDetails,
      },
      {
        text: 'Is active',
        value: 'active',
        sortable: false,
        filter: {
          items: [
            { text: this.$t('Yes'), value: 1 },
            { text: this.$t('No'), value: 0 },
          ],
        },
        hidden: !this.showCustomerDetails,
      },
      {
        text: 'Group',
        value: 'groupId',
        filter: {
          items: Object.keys(this.customerGroups).map((key) => {
            return {
              text: this.customerGroups[key],
              value: key,
            }
          }, this),
        },
        hidden: !this.showCustomerDetails,
      },
      {
        text: 'Sub group',
        value: 'subGroupId',
        filter: {
          items: Object.keys(this.customerSubGroups).map((key) => {
            return {
              text: this.customerSubGroups[key],
              value: key,
            }
          }, this),
        },
        hidden: !this.showCustomerDetails,
      },
      {
        text: 'Show supplier name in Webshop',
        value: 'showSupplierNameInWebshop',
        sortable: false,
        filter: {
          items: [
            { text: this.$t('Yes'), value: 1 },
            { text: this.$t('No'), value: 0 },
          ],
        },
        hidden: !this.showCustomerDetails,
      },
      {
        text: 'Default payment type',
        value: 'defaultPaymentType',
        hidden: !this.showCustomerDetails,
      },
      {
        text: 'Created',
        value: 'createdAt',
        filter: { disable: true },
        hidden: !this.showCustomerDetails,
      },
    ]

    this.headers = this.actionHeaders.concat([
      {
        text: 'Actions',
        value: 'actions',
      },
    ])
  }

  getRoute() {
    return {
      name: this.$route.name,
      params: this.$route.params,
      query: {
        ...this.$route.query,
      },
    }
  }

  toggleCustomerDetails() {
    this.setLocalStorageBool('advanced', this.showCustomerDetails)
    this.prepHeaders()

    // if (this.showCustomerDetails) {
    //   this.$refs.listView.updateOneFilter('active', '(IN)', [1, 0])
    // } else {
    //   this.$refs.listView.updateOneFilter('active', '', [])
    // }
  }

  getQueryParam(key): string {
    if (this.$route.query) {
      const value = this.$route.query[key]
      if (value instanceof Array) {
        return value[0] ? '' + value[0] : ''
      } else {
        return value ? '' + value : ''
      }
    } else {
      return ''
    }
  }

  itemProcessor(items) {
    const _items = []
    for (let i = 0; i < items.length; i++) {
      const row = items[i]
      row.active = row.active == 1 ? 'Yes' : 'No'
      row.showSupplierNameInWebshop = row.showSupplierNameInWebshop == 1 ? 'Yes' : 'No'
      row.invoiceEmailed = row.invoiceEmailed == 1 ? 'Yes' : 'No'
      row.createdAt = row.createdAt
        ? DateTime.fromISO(row.createdAt as string, { zone: 'UTC' }).toFormat('yyyy-MM-dd HH:mm')
        : ''
      row.type = this.customerTypes[row.type]
      row.groupId = this.customerGroups[row.groupId]
      row.subGroupId = this.customerSubGroups[row.subGroupId]
      _items.push(row)
    }
    return _items
  }

  private get query() {
    return {}
  }

  private get legacyUrl() {
    return appendSitePrefix('/customers')
  }

  private getStoredParamBool(key: string): boolean {
    if (this.$route.query[key] !== undefined) {
      return !!this.$route.query[key]
    } else {
      return this.getLocalStorageBool(key)
    }
  }

  private getLocalStorageBool(key: string): boolean {
    return !!localStorage.getItem('customer-list-' + key)
  }

  private setLocalStorageBool(key: string, value: boolean) {
    localStorage.setItem('customer-list-' + key, value ? '1' : '')
  }

  private get url() {
    return '/v4/site/customers'
  }

  @Watch('showCustomerDetails')
  private onChangeShowCustomerDetails() {
    this.setLocalStorageBool('showCustomerDetails', this.showCustomerDetails)
  }

  @Watch('$route', { deep: true })
  private routeToParams() {
    const sortBy = this.getQueryParam('sortBy')
    // NOTE: Temp field mapping for `Export to CSV` until we move that to v4
    switch (sortBy) {
      case 'createdAt':
        this.sortColumn = '_created'
        break
      case 'customerNumber':
      case 'invoiceEmail':
      case 'defaultPayerName':
      case 'priceListName':
      case 'identificationNumber':
      case 'groupId':
      case 'subGroupId':
        this.sortColumn = ((str: string): string =>
          str
            .split('')
            .map((letter, idx) => {
              return letter.toUpperCase() === letter ? `${idx !== 0 ? '_' : ''}${letter.toLowerCase()}` : letter
            })
            .join(''))(sortBy)
        break
      default:
        this.sortColumn = sortBy ? sortBy.toLocaleUpperCase() : 'name'
        break
    }

    this.sortOrder = this.getQueryParam('sortDesc') === '1' ? 'desc' : 'asc'
  }
}
